<template>
  <div class="timer" :style="{background,color}" v-if="checkDates()">
    <div class="counter row full-width g-32" :class="{'no-border':background}">
      <slot/>

      <div :style="{'margin-right': !background ? 'auto': false}" class="no-flex" style="text-align: center;">
        <p>
          <slot name="title"/>
        </p>

        <div class="numbers row" :style="{color:timer_color}">
        <span>
            <span class="day">{{ days }}</span>
            <span class="meaning" :style="{color:timer_color}">{{ $t('timer.days') }}</span>
        </span>
          <span class="dots">:</span>
          <span>
            <span class="hour">
                <span>{{ hours }}</span>
            </span>
            <span class="meaning" :style="{color:timer_color}">{{ $t('timer.hours') }}</span>
        </span>
          <span class="dots">:</span>
          <span>
            <span class="minute">{{ minutes }}</span>
            <span class="meaning" :style="{color:timer_color}">{{ $t('timer.minutes') }}</span>
        </span>
          <span class="dots">:</span>
          <span>
            <span class="second">{{ seconds }}</span>
            <span class="meaning" :style="{color:timer_color}">&nbsp;</span>
        </span>
        </div>
      </div>

      <div class="title no-flex">{{ text }}</div>
    </div>

  </div>
</template>

<script>

export default {
  props: ['date', 'background', 'color', 'text', 'timer_color'],
  mounted() {
    this.unixDate = new Date(this.date.replace(/-/g, "/")).getTime();
    this.countDifference();

    setInterval(this.countDifference, 1000);
  },
  data() {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      unixDate: null
    };
  },
  methods: {
    countDifference() {
      let date_now = Date.now();

      // get total seconds between the times
      var delta = Math.abs(this.unixDate - date_now) / 1000;

      this.days = this.formatValue(Math.floor(delta / 86400));
      delta -= this.days * 86400;

      this.hours = this.formatValue(Math.floor(delta / 3600) % 24);
      delta -= this.hours * 3600;

      this.minutes = this.formatValue(Math.floor(delta / 60) % 60);
      delta -= this.minutes * 60;

      this.seconds = this.formatValue(parseFloat(delta % 60).toFixed(0));
    },
    formatValue(value) {
      if (value.toString().length === 1) {
        return '0' + value;
      } else {
        return value;
      }
    },
    checkDates() {
      var UserDate = this.date;
      var ToDate = new Date();

      const time1 = new Date(UserDate.replace(' ', 'T')).getTime();
      const time2 = ToDate.getTime();

      if (time1 <= time2) {
        return false;
      }

      return true;
    }
  }
}
</script>

<style scoped lang="scss">
.counter {
  display: flex;
  align-items: center;
  border-radius: 5px;
  border: 1px dashed black;
  justify-content: center;

  &.no-border {
    border: none;
    padding-top: 3px;
  }

  &.vertical {
    @include mobile {
      justify-content: inherit;
      height: 60px;
    }
  }

  .text {
    margin-right: 10px;
  }

  p {
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin: auto auto -5px;

    @include mobile {
      font-size: 11px;
    }
  }

  .discount {
    display: flex;
    flex-direction: column;
    width: 80px;
    height: 80px;
    border-radius: 5px;
    background-color: black;
    align-items: center;
    justify-content: center;
    margin: -5px;

    @include mobile {
      margin-left: 0;
      height: 60px;
      width: 60px;
      margin: -1px;
    }

    .discount-number {
      color: white;
      font-size: 28px;
      font-weight: 600;
      margin-bottom: -6px;

      @include mobile {
        font-size: 20px;
        margin-bottom: 0;
      }
    }

    .discount-text {
      color: white;
      font-weight: 600;

      @include mobile {
        font-size: 10px;
      }
    }
  }

  .numbers {
    color: black;
    font-weight: 600;
    font-size: 30px;
    display: flex;
    margin: auto;

    @include mobile {
      font-size: 24px;
    }

    & > span {
      display: flex;
      flex-direction: column;
      text-align: center;

      span:first-child {
        height: 40px;

        @include mobile {
          height: 29px;
        }
      }

      span.meaning {
        color: black;
        font-size: 10px;
        font-weight: 300;
        align-items: center;
        justify-content: center;

        @include mobile {
          font-size: 9px;
        }
      }
    }

    .dots {
      margin: -12px 5px 0;

      @include smd {
        margin: -10px 5px 0;
      }
    }
  }
}

.title {
  align-items: center;
  display: flex;
  margin-left: 32px;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: 1.76px;
  text-transform: uppercase;

  @include smd {
    font-size: 12px;
  }
}

.no-border {
  height: 56px;

  .numbers {
    font-size: 24px;

    @include smd {
      font-size: 20px;
    }

    & > span {
      display: flex;
      flex-direction: column;
      text-align: center;

      span:first-child {
        height: 24px;
        margin-bottom: 5px;

        @include smd {
          margin-bottom: 2px;
        }
      }
    }

    .dots {
      margin: -10px 5px 0;
      font-size: 14px;

      @include smd {
        margin: -10px 4px 0;
        margin-right: 2px;
        font-size: 20px;
      }
    }
  }
}
</style>
